import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from '../../components/FlexContainer';
import MainFile from '../../components/FormComponent/MainFile';
import TextField from '../../components/FormComponent/TextField';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type MagazineTemplateType = BasicContentTemplateType;

export default function MagazineTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: MagazineTemplateType) {
  const {
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <TextField
          label="Ano"
          name="years"
          disabled={view}
          flex="1 0 13.75rem"
        />
        <TextField
          label="Editora"
          name="publishing_company"
          disabled={view}
          flex="1 0 13.75rem"
        />
        <TextField
          label="Extensão"
          name="extension"
          disabled={view}
          flex="1 0 13.75rem"
        />
      </FlexContainer>

      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <MainFile
          getValues={getValues}
          titleCompany={'Arquivo'}
          setValue={setValue}
          control={control}
          content={content}
          view={view}
          permission="public"
        />
      </FlexContainer>
    </BasicContentTemplate>
  );
}
