import * as I from '@mui/icons-material';
import React, { forwardRef, Ref } from 'react';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button/index';
import { FlexContainer } from '../../components/FlexContainer';
import Heading from '../../components/Heading/index';
import Table, { TableProps } from '../../components/Table';
import * as S from './styles';

type ContentTemplateProps = {
  linkToCreatePage: string;
  pageName:
    | 'artigos'
    | 'e-books'
    | 'Ferramentas de Gestão'
    | 'infográficos'
    | 'kits temáticos'
    | 'pesquisas'
    | 'podcasts'
    | 'revistas'
    | 'vídeos'
    | 'coleções'
    | 'autores'
    | 'aulas'
    | 'módulos'
    | 'cursos'
    | 'episódios'
    | 'temporadas'
    | 'séries'
    | 'aulas síncronas'
    | 'módulos síncronos'
    | 'conteúdo exclusivo'
    | 'lista de usuários'
    | 'lista de moderadores'
    | 'lista de turmas'
    | 'cursos síncronos';
  breadcrumbs: React.ReactElement[];
  handleSearch?: () => Promise<void>;
} & TableProps;

export default forwardRef(function ContentTemplate(
  props: ContentTemplateProps,
  ref: Ref<HTMLInputElement>,
) {
  const { linkToCreatePage, pageName, breadcrumbs, handleSearch, ...rest } =
    props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(linkToCreatePage);
  };

  const searchTitlePlaceholder = 'Busque pelo título';
  const searchNamePlaceholder = 'Busque pelo nome';

  const customPlaceholder = {
    artigos: searchTitlePlaceholder,
    'e-books': searchTitlePlaceholder,
    'Ferramentas de Gestão': searchTitlePlaceholder,
    infográficos: searchTitlePlaceholder,
    'kits temáticos': searchTitlePlaceholder,
    pesquisas: searchTitlePlaceholder,
    podcasts: searchTitlePlaceholder,
    revistas: searchTitlePlaceholder,
    vídeos: searchTitlePlaceholder,
    'conteúdo exclusivo': searchTitlePlaceholder,
    coleções: searchNamePlaceholder,
    autores: searchNamePlaceholder,
    aulas: searchNamePlaceholder,
    módulos: searchNamePlaceholder,
    cursos: searchNamePlaceholder,
    episódios: searchNamePlaceholder,
    temporadas: searchNamePlaceholder,
    séries: searchNamePlaceholder,
    'aulas síncronas': searchNamePlaceholder,
    'módulos síncronos': searchNamePlaceholder,
    'cursos síncronos': searchNamePlaceholder,
    'lista de usuários': searchNamePlaceholder,
    'lista de moderadores': searchNamePlaceholder,
    'lista de turmas': `${searchTitlePlaceholder} da turma`,
  };

  return (
    <S.Wrapper>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <S.Header>
        <Heading
          style={{ textTransform: 'capitalize' }}
          level={1}
          size="xlarge"
          fontWeight={600}
          lineHeight="2rem"
        >
          {pageName}
        </Heading>

        <Button
          startIcon={<I.AddCircle />}
          variant="contained"
          onClick={handleClick}
        >
          Adicionar novo
        </Button>
      </S.Header>

      {handleSearch && (
        <FlexContainer gap="1rem" alignItems="center" alignSelf="flex-end">
          <S.Input ref={ref} placeholder={customPlaceholder[pageName]} />
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
        </FlexContainer>
      )}

      <Table height="100%" {...rest} />
    </S.Wrapper>
  );
});
