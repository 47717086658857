import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/bubble.css';

import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import {
  CropperImage,
  CropperRef,
  CropperState,
  CropperTransitions,
  ImageRestriction,
} from 'react-advanced-cropper';
import { createPortal } from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { ICategory } from '../../models/category';
import { uploadFile } from '../../services/files';
import { AppCropper } from '../AppCropper';
import FileInput from '../FileInput';
import ImageEditingModal from '../SimpleModal/ImageEditingModal';
import * as S from './styles';

export type UploadCoverProps = {
  isView: boolean;
  showCategory?: boolean;
  isCircularImage?: boolean;
  sizes?: {
    width: number;
    heigth: number;
  };
  keyFileId?: string;
  keyImageFile?: string;
  keyImageName?: string;
  keyImageTooltip?: string;
};

export type FileInfoProps = {
  name: string;
  size: number;
  type: string;
  data: string;
  height?: number;
  width?: number;
};

export type CropperSettings = {
  aspectRatio?: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  imageRestriction?: ImageRestriction;
  stencilType?: 'rectangle' | 'circle';
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  scaleImage?: boolean;
  grid?: boolean;
};

interface PreviewState {
  state: CropperState | null;
  image: CropperImage | null;
  transitions: CropperTransitions | null;
  loading?: boolean;
  loaded?: boolean;
}

export default function UploadCover({
  isView,
  showCategory = false,
  isCircularImage = false,
  sizes = { width: 1400, heigth: 782 },
  keyFileId = 'image_file_id',
  keyImageFile = 'image_file',
  keyImageName = 'image_name',
  keyImageTooltip = 'image_toltip',
}: UploadCoverProps) {
  const [srcHistoric, setSrcHistoric] = useState<string[] | []>([]);
  const [src, setSrc] = useState<string | undefined>();
  const [previewState, setPreviewState] = useState<PreviewState>({
    state: null,
    image: null,
    transitions: null,
  });
  const [stencilType, setStencilType] = useState<'circle' | 'rectangle'>(
    isCircularImage ? 'circle' : 'rectangle',
  );
  const [settings, setSettings] = useState<CropperSettings>();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>(
    {} as FileInfoProps,
  );
  const [backCategories, setBackCategories] = useState<string[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [childrenCategories, setChildrenCategories] = useState<ICategory[]>([]);

  const cropperRef = useRef<CropperRef>(null);

  const {
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const imageFile = getValues(keyImageFile);
    if (imageFile) {
      setFileInfo({
        name: imageFile.name,
        size: Number(imageFile.size),
        type: imageFile.type,
        data: format(new Date(imageFile.created_at), 'dd/MM/yyyy'),
      });
      setSrc(imageFile.reference);
      !srcHistoric.length && setSrcHistoric([imageFile.reference]);

      if (showCategory) {
        const childrensCategories = imageFile.categories.filter(
          category => category.parent_id !== null,
        );
        setCategories(imageFile.categories);
        setChildrenCategories(childrensCategories);
        setBackCategories(
          imageFile.categories.map(category => category.category_id),
        );
      }
    }
  }, [getValues(keyFileId)]);

  const onUpdate = (cropper: CropperRef) => {
    setPreviewState({
      state: cropper.getState(),
      image: cropper.getImage(),
      transitions: cropper.getTransitions(),
      loaded: cropper.isLoaded(),
      loading: cropper.isLoading(),
    });
  };

  const onCrop = () => {
    if (cropperRef.current) {
      setSrc(cropperRef.current.getCanvas()?.toDataURL());
    }
  };

  const handleHistoric = () => {
    if (srcHistoric.length) {
      setSrc(srcHistoric[0]);
    }
  };

  const handleSettings = (settings: CropperSettings) => {
    if (cropperRef.current) {
      setSettings(prevState => ({
        ...prevState,
        ...settings,
      }));
    }
  };

  const handleStencilType = (type: 'circle' | 'rectangle') => {
    if (cropperRef.current) {
      setStencilType(type);
    }
  };

  const handleRemoveImage = () => {
    if (cropperRef.current) {
      setSrc(undefined);
      setSrcHistoric([]);
      setFileInfo(null);
      setBackCategories([]);
      setChildrenCategories([]);
      setCategories([]);

      setValue(keyImageName, null);
      setValue(keyImageTooltip, null);
      setValue(keyFileId, null);
      setValue(keyImageFile, null);

      if (errors[keyImageFile]) {
        clearErrors(keyImageFile);
      }
    }
  };

  const validationSizes = (width: number, height: number) => {
    if (width !== sizes.width) {
      setError(keyImageFile, {
        message: `A imagem não pode ter a largura maior que ${sizes.width}px`,
      });

      return;
    }

    if (height !== sizes.heigth) {
      setError(keyImageFile, {
        message: `A imagem não pode ter a altura maior que ${sizes.heigth}px`,
      });

      return;
    }

    if (
      errors[keyImageFile] &&
      width === sizes.width &&
      height === sizes.heigth
    ) {
      clearErrors(keyImageFile);
    }
  };

  const validationCategories = () => {
    if (showCategory && !categories.length) {
      setError(`${keyImageFile}.categories`, {
        message: 'É necessario escolher pelo menos uma categoria.',
      });
      return { isError: true };
    } else {
      clearErrors(`${keyImageFile}.categories`);
    }

    if (showCategory && !childrenCategories.length) {
      setError(`${keyImageFile}.categories`, {
        message: 'É necessario escolher pelo menos uma categoria filha.',
      });
      return { isError: true };
    } else {
      clearErrors(`${keyImageFile}.categories`);
    }
  };

  const handleUploadImage = () => {
    const canvas = cropperRef.current?.getCanvas();
    const validationResult = validationCategories();

    if (showCategory && validationResult?.isError) {
      return;
    }

    if (openModal) {
      setOpenModal(false);
    }

    if (canvas && !errors[keyImageFile]) {
      const formData = new FormData();
      let categoryCopy: any[] = [];

      if (showCategory) {
        categoryCopy = categories;
        categoryCopy.forEach(el =>
          formData.append('categories[]', el.category_id),
        );

        setValue(`${keyImageFile}.categories`, categories);
      }

      canvas.toBlob(blob => {
        if (blob && fileInfo) {
          const file = new File([blob], fileInfo.name, { type: 'image/png' });
          formData.append('file', file);
          formData.append('permission', 'public');
          formData.append('compress', 'true');
          formData.append(
            'tooltip',
            !fileInfo ? getValues('title') : fileInfo.name,
          );

          uploadFile(formData)
            .then(data => {
              setSrc(data.reference);
              setValue(keyFileId, data.file_id);
              setValue(keyImageFile, data);
              setValue(keyImageName, fileInfo.name);
              setValue(keyImageTooltip, fileInfo.name);
              setIsEdit(false);
              openModal && setOpenModal(false);

              toast.success('Upload de imagem feita com sucesso');
            })
            .catch(error => {
              toast.error(error.message);
              console.error(error);
            });
        } else {
          Swal.fire({
            title: 'Erro ao fazer upload do arquivo',
            icon: 'error',
            buttonsStyling: false,
            customClass: {
              title: 'modal-title',
              htmlContainer: 'modal-html-container',
              confirmButton: 'btn-ok',
            },
          });
          console.error('Erro ao fazer upload do arquivo');
        }
      });
    }
  };

  const uploadImage = () => {
    const width = cropperRef.current?.getState()?.imageSize.width;
    const height = cropperRef.current?.getState()?.imageSize.height;

    if (width && height) validationSizes(width, height);

    if (showCategory && !errors[keyImageFile]) {
      setOpenModal(!openModal);
    } else {
      handleUploadImage();
    }
  };

  const onLoadImage = (files: File[]) => {
    const blob = URL.createObjectURL(files[0]);

    setFileInfo({
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      data: format(files[0].lastModified, 'dd/MM/yyyy'),
    });
    setSrc(blob);
    setSrcHistoric([blob]);
  };

  const handleDownloadImage = (src?: string) => {
    if (!src) {
      toast.error('Não foi possivel encontrar a imagem');
      return;
    }

    fetch(src)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'image/png' }),
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', getValues(keyImageName));

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Erro ao baixar a imagem:', error);
        toast.error(`Erro ao baixar a imagem: ${error}`);
      });
  };

  const dropzone = useDropzone({
    onDrop: onLoadImage,
    accept: {
      'image/*': ['.jpeg', '.png', '.svg'],
    },
    disabled: isView,
  });

  const navigationProps = {
    onCrop,
    isView,
    validationSizes,
    handleHistoric,
    handleSettings,
    handleStencilType,
    handleRemoveImage,
    handleDownloadImage,
    handleUploadImage: uploadImage,
    openCategoryModal: () => setOpenModal(true),
    handleEdit: () => setIsEdit(!isEdit),
  };

  return (
    <>
      <S.Wrapper>
        {errors[keyImageFile] && errors[keyImageFile].message && (
          <S.InvalidFeedback>{errors[keyImageFile].message}</S.InvalidFeedback>
        )}
        <S.Container>
          <S.Editor>
            {src ? (
              <AppCropper
                className="app-cropper"
                src={src}
                ref={cropperRef}
                navigationProps={navigationProps}
                stencilProps={settings}
                stencilType={stencilType}
                defaultSize={{
                  width: sizes.width,
                  height: sizes.heigth,
                }}
                isEdit={isEdit}
                onUpdate={onUpdate}
                previewState={previewState}
              />
            ) : (
              <FileInput
                id={keyFileId}
                dropzone={dropzone}
                text="Clique para adicionar uma imagem ou arraste e solte a imagem nesta
              área"
              />
            )}
          </S.Editor>
        </S.Container>
      </S.Wrapper>
      {openModal &&
        createPortal(
          <ImageEditingModal
            onCloseChange={() => setOpenModal(!openModal)}
            handleSend={handleUploadImage}
            categories={{
              backCategories,
              setBackCategories,
              setCategories,
              setChildrenCategories,
            }}
            keyImageFile={keyImageFile}
            keyImageName={keyImageName}
            keyImageTooltip={keyImageTooltip}
            isOpen={openModal}
            isView={isView}
          />,
          document.body,
        )}
    </>
  );
}
